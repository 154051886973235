import React from 'react';
import { receiveFail } from '../donations/donations.actions';
import * as Queries from '../../library/query.module';
import { rebuildApiHeaders } from '../../library/functions';

export const REQUEST_CAMPAIGNS = 'REQUEST_CAMPAIGNS';

export function requestCampaigns(accountIdentifier, headers) {
  return {
    type: REQUEST_CAMPAIGNS,
    account_identifier: accountIdentifier,
    headers,
  };
}

export const SET_CAMPAIGN_QUERY = 'SET_CAMPAIGN_QUERY';

export function setCampaignQuery(query) {
  return {
    type: SET_CAMPAIGN_QUERY,
    query,
  };
}

export const RECEIVE_CAMPAIGNS = 'RECEIVE_CAMPAIGNS';

export function receiveCampaigns(accountIdentifier, json) {
  return {
    type: RECEIVE_CAMPAIGNS,
    account_identifier: accountIdentifier,
    campaigns: json.data,
    summary: json.summary,
    orderBy: json.params,
    receivedAt: Date.now(),
  };
}

export const REQUEST_CAMPAIGN = 'REQUEST_CAMPAIGN';

export function requestCampaign(accountIdentifier, headers, campaignId) {
  return {
    type: REQUEST_CAMPAIGN,
    account_identifier: accountIdentifier,
    headers,
    campaignId,
  };
}

export const SELECTED_CAMPAIGN = 'SELECTED_CAMPAIGN';

export function selectedCampaign(campaign) {
  return {
    type: SELECTED_CAMPAIGN,
    campaign,
  };
}

export const INVALIDATE_CAMPAIGN = 'INVALIDATE_CAMPAIGN';

export function invalidateCampaign(campaign) {
  return {
    type: INVALIDATE_CAMPAIGN,
    campaign,
  };
}

export const EDIT_CAMPAIGN = 'EDIT_CAMPAIGN';

export function editCampaign(campaignId, headers) {
  return {
    type: EDIT_CAMPAIGN,
    campaignId,
    headers,
  };
}

export const RECEIVE_EXPORT = 'RECEIVE_EXPORT';

export function receiveExport(message) {
  return {
    type: RECEIVE_EXPORT,
    message,
  };
}

export const RECEIVE_CAMPAIGNS_RECURSIVELY = 'RECEIVE_CAMPAIGNS_RECURSIVELY';

export function receiveCampaignsRecursively(campaigns, accountIdentifier) {
  return {
    type: RECEIVE_CAMPAIGNS_RECURSIVELY,
    account_identifier: accountIdentifier,
    campaigns,
  };
}

export const exportCampaigns = (
  accountIdentifier,
  headers,
  offset = '0',
  query = { status: 'all' }
) => {
  let queryVal = query;
  if (queryVal && !queryVal.hasOwnProperty('status')) {
    queryVal = { ...queryVal, status: 'all' };
  }

  const url = Queries.getCampaignsQuery(accountIdentifier, offset, queryVal);

  return fetch(url, {
    method: 'GET',
    json: true,
    rejectUnauthorized: false,
    headers,
  });
};

export function fetchCampaignsV2(
  accountIdentifier,
  headers,
  offset = '0',
  query = { status: 'all' }
) {
  let queryVal = query;

  if (queryVal && !queryVal.hasOwnProperty('status')) {
    queryVal = { ...queryVal, status: 'all' };
  }

  const url = Queries.getCampaignsQuery(accountIdentifier, offset, queryVal);

  if (queryVal.hasOwnProperty('export_columns')) {
    return dispatch =>
      fetch(url, {
        method: 'GET',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers, '2022-12-15'),
      }).then(function(response) {
        if (response.status === 200) {
          dispatch(receiveExport('success'));
        } else if (response.status === 404 || response.status === 500) {
          dispatch(receiveExport('error'));
        }
      });
  }
  return dispatch => {
    dispatch(requestCampaigns(accountIdentifier, headers));
    return fetch(url, {
      method: 'GET',
      json: true,
      rejectUnauthorized: false,
      headers: rebuildApiHeaders(headers, '2022-12-15'),
    }).then(function(response) {
      if (response.status !== 200) {
        console.log('error');
      } else {
        return response
          .json()
          .then(json => dispatch(receiveCampaigns(accountIdentifier, json)))
          .then(dispatch(setCampaignQuery(queryVal)));
      }
    });
  };
}

export function fetchCampaign(accountIdentifier, headers, campaignId) {
  return dispatch => {
    dispatch(requestCampaign(accountIdentifier, headers, campaignId));
    return fetch(
      `${process.env.DONATELY_API_V2_URL}/campaigns/${campaignId}.json?account_id=${accountIdentifier}`,
      {
        method: 'GET',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers, '2022-12-15'),
      }
    ).then(function(response) {
      if (response.status !== 200) {
        console.log('error');
      } else {
        return response
          .json()
          .then(json => dispatch(selectedCampaign(json.data)));
      }
    });
  };
}

export function fetchCampaignV2(
  campaignId,
  apiVersion = '2022-12-15',
  draftId = null
) {
  return async (dispatch, getState) => {
    const {
      accountReducer: {
        selectedAccount: { id: accountIdentifier },
        storeUser: headers,
      },
    } = getState();
    let url = `${process.env.DONATELY_API_V2_URL}/campaigns/${campaignId}.json?account_id=${accountIdentifier}`;
    if (draftId) {
      url = `${url}&draft_id=${draftId}`;
    }
    const response = await fetch(url, {
      method: 'GET',
      json: true,
      rejectUnauthorized: false,
      headers: rebuildApiHeaders(headers, apiVersion),
    });
    const json = await response.json();
    if (response.status === 200) {
      const result = {
        ...json.data,
        summary: json.summary,
      };
      dispatch(selectedCampaign(result));
      return result;
    }
    return null;
  };
}

export function updateCampaign(
  accountIdentifier,
  headers,
  campaignId,
  updateValues
) {
  return dispatch => {
    dispatch(editCampaign(campaignId, headers));
    return fetch(
      `${process.env.DONATELY_API_V2_URL}/campaigns/${campaignId}.json?account_id=${accountIdentifier}`,
      {
        method: 'POST',
        body: JSON.stringify(updateValues),
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers, '2022-12-15'),
      }
    ).then(function(response) {
      if (response.status !== 200) {
        return response
          .json()
          .then(json => dispatch(receiveFail(json.message)));
      }
      return response
        .json()
        .then(json => dispatch(selectedCampaign(json.data)))
        .then(dispatch(fetchCampaignsV2(accountIdentifier, headers)));
    });
  };
}
export function createDraftV2(campaignId, updateValues) {
  return updateCampaignV2(campaignId, {
    ...updateValues,
    draft: true,
  });
}
export function updateCampaignV2(
  campaignId,
  updateValues,
  apiVersion = '2022-12-15'
) {
  return async (dispatch, getState) => {
    const {
      accountReducer: {
        selectedAccount: { id: accountIdentifier },
        storeUser: headers,
      },
    } = getState();
    await dispatch(editCampaign(campaignId, headers));
    const response = await fetch(
      `${process.env.DONATELY_API_V2_URL}/campaigns/${campaignId}.json?account_id=${accountIdentifier}`,
      {
        method: 'POST',
        body: JSON.stringify(updateValues),
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers, apiVersion),
      }
    );
    const json = await response.json();
    if (response.status !== 200) {
      dispatch(receiveFail(json.message));
    } else {
      dispatch(selectedCampaign(json.data));
      return json.data;
    }
  };
}
export function createCampaign(newValues, apiVersion = '2022-12-15') {
  return async (dispatch, getState) => {
    const {
      accountReducer: {
        selectedAccount: { id: accountIdentifier },
        storeUser: headers,
      },
    } = getState();

    const response = await fetch(
      `${process.env.DONATELY_API_V2_URL}/campaigns.json?account_id=${accountIdentifier}`,
      {
        method: 'POST',
        body: JSON.stringify(newValues),
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers, apiVersion),
      }
    );
    const json = await response.json();
    if (response.status !== 200) {
      dispatch(receiveFail(json.message));
      return null;
    }
    dispatch(selectedCampaign(json.data));
    dispatch(fetchCampaignsV2(accountIdentifier, headers));
    return json.data;
  };
}

export function shouldFetchCampaigns(state, accountIdentifier) {
  const campaigns = state.campaignReducer.campaignsByAccount[accountIdentifier];
  if (!campaigns) {
    return true;
  }
  if (campaigns.isFetching || campaigns.items) {
    return false;
  }
}

export function fetchCampaignsIfNeeded(accountIdentifier, headers) {
  return (dispatch, getState) => {
    if (shouldFetchCampaigns(getState(), accountIdentifier)) {
      return dispatch(fetchCampaignsV2(accountIdentifier, headers));
    }
  };
}

export function destroyCampaign(accountIdentifier, headers, campaignId) {
  return async dispatch => {
    const response = await fetch(
      `${process.env.DONATELY_API_V2_URL}/campaigns/${campaignId}.json?account_id=${accountIdentifier}`,
      {
        method: 'DELETE',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    );
    const json = await response.json();
    if (response.status === 200) {
      await dispatch(fetchCampaignsV2(accountIdentifier, headers));
    }

    return json;
  };
}

export function fetchAllCampaignsRecursively(
  accountIdentifier,
  headers,
  query = { status: 'published,hidden', order: 'ASC', order_by: 'title' }
) {
  return async dispatch => {
    const getUrlFromLimitOffset = (off, lim) => {
      const queryVal = { ...query, limit: lim };
      return Queries.getCampaignsQuery(accountIdentifier, off, queryVal);
    };
    // make the batch size 100

    // first
    let shouldFetch = true;
    let currentBatchCampaigns = [];
    let totalCampaigns = [];

    await dispatch(requestCampaigns(accountIdentifier, headers));

    const limit = 100;
    let offset = 0;
    let errorCounter = 0;
    const isDonor = localStorage.getItem('isDonor');

    while (shouldFetch) {
      const response = await fetch(getUrlFromLimitOffset(offset, limit), {
        method: 'GET',
        json: true,
        rejectUnauthorized: false,
        // if is donor, send unauthenticated request
        headers:
          isDonor === 'true' ? {} : rebuildApiHeaders(headers, '2022-12-15'),
      });
      if (response.status === 200) {
        const campaignJson = await response.json();
        offset += limit;
        currentBatchCampaigns = campaignJson.data;
        totalCampaigns = [...totalCampaigns, ...currentBatchCampaigns];
        shouldFetch = currentBatchCampaigns.length === limit;
      } else {
        errorCounter += 1;
        dispatch(receiveFail(`Error fetching campaigns: ${response.message}`));
        shouldFetch = errorCounter <= 10;
      }
    }
    dispatch(receiveCampaignsRecursively(totalCampaigns, accountIdentifier));

    return totalCampaigns;
  };
}
